<template>
    <div style="margin-top: 100px">
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12" >
                <div class="row">
                    <div class="col-12">
                        <div class="row" >
                            <div class="col-12 col-md-6 col-lg-8">
                                
                                <div class="form-group d-flex" >
                                    <input type="search" class="form-control campo-procurar" v-model="search" placeholder="Procurar">
                                    <button type="submit" class="btn btn-info" @click="fetchLeads">Buscar</button>
                                </div>
                               
                            </div>
                           
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-9 col-md-6 col-lg-10 p-0 order-3 align-self-center">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                    <p class="text-truncate title-legend-list">Nome/E-mail</p>
                                </div>
                                <div class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list">
                                    <p class="text-truncate title-legend-list">Whatsapp</p>
                                </div>
                                <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                    <p class="text-truncate title-legend-list">Origem</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 col-md-2 col-lg-2 align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                </div>

                <div class="card-list" v-for="lead in leads"> 
                    <div class="card-body-list"> 
                        <div class="col-12 p-0 align-self-center">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-9 col-md-6 col-lg-10 p-0 order-3 align-self-center">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                    <h6 class="text-truncate card-body-list-title">{{lead.name}}</h6>
                                                    <h6 class="text-truncate cpf">{{lead.email}}</h6>
                                                </div>
                                                <div class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list">
                                                    <h6 class="text-truncate e-mail">{{lead.telefone}}</h6>
                                                </div>
                                                <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                    <h6 class="text-truncate" style="font-size: 10px">{{lead.created_at}}</h6>
                                                    <h6 v-if="lead.origin != null" class="text-truncate whats"><b>{{lead.origin.name}}</b></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 col-md-2 col-lg-2 align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                        <a href="javascript:void(0);" @click="deleteItem(lead.id)" class="btn btn-danger text-white rounded-circle btn-circle font-16"><i class="far fa-trash-alt"></i></a>
                                        <a class="btn btn-success ml-1 text-white rounded-circle btn-circle font-20 card-body-list-cta" target="_blank" :href="`https://api.whatsapp.com/send?phone=${lead.whatsapp.replace(/\D/g,'')}`"><i class="fab fa-whatsapp font-20"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                
                <div class="col-12 text-center p-4" v-show="!leads.length">
                    Nenhum lead registrado.
                </div>
            </div>
            <div class="col-12">
                <BasePagination
                    v-if="leads.length > 0"
                    :totalPages="pagination.totalPages"
                    :activePage="pagination.currentPage"
                    @to-page="toPage"
                    @per-page="perPage" />
            </div>
        </div>
    </div>

    </template>
    <script>
    import Leads from  "@/services/resources/LeadService";

    const service = Leads.build();
    
    export default {
        data(){
            return{
                leads: [],
                pagination: {
                    totalPages: 1,
                    currentPage: 1,
                    perPage: 10,
                    itens: 0
                },
                search: null,
                pages:[
                    {
                        name: 'dashboard',
                        to: 'Dashboard'
                    },
                    {
                        name: 'base de leads',
                        to: 'LeadsList'
                    }
                ],
               

            }
        },
        computed: {
            isQsuiteAdmin() {
               
                return this.$store.getters.isQsuiteAdmin;
            }
        },
        watch: {
            
        },
        methods:{

            deleteItem(id){
    
                this.$bvModal.msgBoxConfirm('Tem certeza que deseja excluir esse chamado?')
                .then(value => {

                if(value) {
                    let data = {
                        id: id
                    };

                    service
                    .destroy(data)
                    .then(resp => {
                        this.$bvToast.toast('Seu lead foi excluído com sucesso!', {
                            title: 'Excluir chamado',
                            autoHideDelay: 5000,
                            type: 'success'
                        })
                        this.fetchLeads();
                    })
                    .catch(err => {
                        this.$bvToast.toast('Seu lead não pode ser excluído.', {
                            title: 'Excluir produto',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }
                    
                })
                .catch(err => {
                // An error occurred
                })

            },
          
            toPage(page) {
                this.pagination.currentPage = page;
                this.fetchLeads();
            },
            perPage(qtd) {
                this.pagination.currentPage = 1;
                this.pagination.perPage = qtd;

                this.fetchLeads();
            },
            fetchLeads(){
    
                let data = {
                    page: this.pagination.currentPage,
                    per_page: this.pagination.perPage
                }
    
                if(this.search?.length) {
                    data['search'] = this.search;
                }
                 service
                    .search(data)
                    .then(resp => {
                        let createdAtTreated = '';
                        var leads=[];
                       var informacao = resp.data;
                        informacao.forEach(item => {
                            createdAtTreated = item.updated_at.split('T')[0];
                            
                            createdAtTreated = createdAtTreated.split('-');
                            createdAtTreated = `${createdAtTreated[2]}/${createdAtTreated[1]}/${createdAtTreated[0]}`
                            createdAtTreated = `${createdAtTreated} | ${item.updated_at.split('T')[1].slice(0, 8)}`

                            leads[leads.length] = {
                                ...item,
                                created_at: createdAtTreated,
                                whatsapp: item.telefone
                            }
                        });
                        this.$nextTick(() => {
                                this.leads= leads
                        })
                         

                        this.pagination.totalPages = resp.last_page;
                        this.pagination.itens = resp.total;
                    
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        mounted(){
            this.fetchLeads();
        }
    }
    </script>

    <style scoped>
    .Novo {
        background-color: lightblue;
    }

    .Pendente {
        background-color: crimson;
    }

    .Aberto {
        background-color: lightseagreen
    }

    .Emespera {
        background-color: yellow
    }

    .Resolvido {
        background-color: greenyellow
    }

    .Fechado {
        background-color: black
    }

    </style>